// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-blog-post-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-category-list-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-categories-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-error-html-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/error.html.js" /* webpackChunkName: "component---src-pages-error-html-js" */),
  "component---src-pages-open-source-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-tags-js": () => import("/home/runner/work/alvareznavarro/alvareznavarro/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

